import React, { useState } from 'react';
import '../styles/ProjectsGallery.css';
import ProjectDetail from './ProjectDetails';
import data from '../data/projects.json';

function ProjectGallery() {
  const [projects] = useState(data);
  const [selectedProject, setSelectedProject] = useState(projects[0]);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <section id='mes-projets'>
      <div className="container">
        <div className="project-gallery">
          <h2>Mes projets</h2>
          <div className="project-buttons">
            {projects.map((project) => (
              <button
                key={project._id}
                onClick={() => handleProjectClick(project)}
                className={selectedProject.id === project.id ? 'active' : ''}
              >
                {project.title}
              </button>
            ))}
          </div>
          <div className="project-details">
            {selectedProject && <ProjectDetail project={selectedProject} />}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectGallery;